// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-algebra-tutoring-js": () => import("./../../../src/pages/algebra-tutoring.js" /* webpackChunkName: "component---src-pages-algebra-tutoring-js" */),
  "component---src-pages-eighth-grade-js": () => import("./../../../src/pages/eighth-grade.js" /* webpackChunkName: "component---src-pages-eighth-grade-js" */),
  "component---src-pages-high-school-js": () => import("./../../../src/pages/high-school.js" /* webpackChunkName: "component---src-pages-high-school-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sfusd-pathways-js": () => import("./../../../src/pages/sfusd-pathways.js" /* webpackChunkName: "component---src-pages-sfusd-pathways-js" */),
  "component---src-pages-summary-js": () => import("./../../../src/pages/summary.js" /* webpackChunkName: "component---src-pages-summary-js" */)
}

